<template>
    <!-- 报警线路详情页面 -->
    <div class="conenters">
        <!-- 头部开始 -->
        <el-row class="pur-top">
        <el-col :span="3">
            <span class="pur-size">{{ purTitle }}</span></el-col>
            <el-col :span="21" class="pur-right">
                <el-button size="small" style="margin-left: 24px"  @click="Addopen">返回</el-button>
                <!-- <el-button size="small" style="margin-left: 24px" icon="el-icon-video-play" @click="reset">停止回放</el-button>
                <el-button size="small" style="margin-left: 24px" icon="el-icon-video-pause" @click="starts">开始回放</el-button> -->
            </el-col>
        </el-row>
        <!-- 头部结束 -->
        <!-- 内容开始 -->
        <div class="pur-nav">
            <ul class="mapBox">
                <li style="width: 40%;">
                    <p style="background:blue;"></p>
                </li>
                <li>
                    <span>预定围栏路线</span>
                </li>
                <li style="width: 40%;">
                    <p style="background:red;"></p>
                </li>
                <li>
                    <span>实际行驶路线</span>
                </li>
            </ul>
            <baidu-map class="bm-view" MapType="BMAP_SATELLITE_MAP" :center="centermap" :zoom="zoom" :scroll-wheel-zoom="true" :continuous-zoom="true">
                <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                <!-- 地图缩放控件 -->
                <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT" :offset="{width:10,height:10}"></bm-navigation>
                <!-- 地图类型 -->
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT" :offset="{width:100,height:26}"></bm-map-type>
                <!-- 城市列表 -->
                <bm-city-list anchor="BMAP_ANCHOR_TOP_LEFT" :offset="{width:280,height:24}"></bm-city-list>
                <!-- 定位控件 -->
                <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true" :offset="{width:10,height:26}"></bm-geolocation>
                <!-- 起始点路线规划控件 -->
                <!-- <bm-driving :panel="false" :autoViewport="true" ></bm-driving> -->
                <!-- <bm-driving @searchcomplete="handleSearchComplete" :panel="false" :autoViewport="true"></bm-driving> -->
                <!-- 路书轨迹回放功能 -->
                <!-- <bml-lushu
                @stop="reset"
                @start="starts"
                :path="path"
                :icon="icon"
                :play="play"
                :speed="100"
                :autoView="true"
                :content="vehicles"
                :rotation="true">
                </bml-lushu> -->
                <!-- 折线地图 -->
                <bm-polyline v-for="(v,k) in newpath" :key="k" :path="v.path" :stroke-color="v.rgb" :stroke-opacity="0.6" :stroke-weight="12" :editing="false"></bm-polyline>
                <!-- 折现轨迹回放 -->
                <bm-polyline :path="newlinePath" stroke-color="red" :stroke-opacity="0.4" :stroke-weight="8" :editing="false"></bm-polyline>
                <!-- 回放点 -->
                <bm-marker :position="{lng:dpstop.lng,lat:dpstop.lat}"  :dragging="false" :icon="{url: require('../assets/carstop.png'), size: {width:48, height:48}}">
                    <bm-label :content="dpstop.licensePlate+ ',' + (dpstop.speed/10) + 'km/h'" :labelStyle="{color: 'red', fontSize : '18px'}" :offset="{width: -40, height: -30}"/>
                </bm-marker>
                <!-- 报警点 -->
                <bm-marker :position="markerPoint" :dragging="false" :icon="{url: require('../assets/jgtp.png'), size: {width:48, height:48}}">
                </bm-marker>
            </baidu-map>
            <div class="speedBox">
                <div class="ibuttsn">
                    <i class="el-icon-video-pause" v-if="play" @click="reset"></i>
                    <i class="el-icon-video-play" v-else @click="starts"></i>
                </div>
                <div class="sliderbox">
                    <el-slider el-slider v-model="vlidalue" :format-tooltip="lidformatip" :show-tooltip="false" :max="slidermax"></el-slider>
                </div>
            </div>
        </div>
        <!-- 内容结束 -->
    </div>
</template>
<script>
var newlinePath = [];
import {BmlLushu} from 'vue-baidu-map'
import zcar from '../assets/carsleft.png'
import gcoord from 'gcoord'
export default {
    components: {
        BmlLushu,
    },
    data() {
        return {
            purTitle:'',
            // 地图
            play: false,
            markerPoint:{},
            icon: {
                url: zcar,
                size: {width: 52, height: 26},
                opts: {anchor: {width: 27, height:13}}
            },
            path:[],
            vehicles:'',                            // 车辆信息
            polylinePath: [],                       // 围栏轨迹集合
            
            centermap:{},
            zoom:15,
            AddData:{},
            newpath:[],
            newlinePath:[],                         // 轨迹回放路径折线合集
            vlidalue:1,    // 滑块
            slidermax:null, // 滑块值
            dpstop:{},      // 回放点
            intartime:null, // 计时器
        };
    },
    created() {
        this.purTitle = this.$route.meta.title;  
        // 接收主页单条数据
        this.AddData = JSON.parse(sessionStorage.getItem('queryAll'));  
        // console.log(this.AddData)
        // 折现路径绘制
        this.api.riskAlarm.one({id:this.AddData.id})
        .then(res=>{
            if(res.data.code == 200){
                let AddData = res.data.data;
                // 折现路径绘制
                let rgb = ['#3385ff','#000',"#33ff33","#c65353","#9933ff"];
                let arrpath;
                let objmap;
                for(let key in AddData.fences){
                    this.polylinePath = []
                    arrpath = AddData.fences[key].fenceContent.split(';');
                    for(let i=0;i<arrpath.length-1;i++){
                        objmap = arrpath[i].split(',');
                        this.polylinePath.push({lng:objmap[1],lat:objmap[0]})
                    }
                    this.newpath.push(
                        {
                            path:this.polylinePath,
                            rgb:rgb[key]
                        }
                    )
                }
                // 地图中心点
                this.centermap = {
                    lng: this.polylinePath[0].lng,
                    lat: this.polylinePath[0].lat
                }
            }
        });
        // 路书路线绘制
        let newdata={
            travelBatchNb:this.AddData.travelBatchNb,
            licensePlate:this.AddData.licensePlate
        }
        this.markerPoint = {
            lng:this.AddData.alongitude,
            lat:this.AddData.alatitude
        }
        this.api.updatePwd.all(newdata)
        .then(res=>{
            if(res.data.data.code == 200){
                if(res.data.data.data.sjlist){
                    this.slidermax = res.data.data.data.sjlist.length*1;
                    // console.log(res.data.data.data.sjlist)  // 轨迹回放点集合
                    this.play = false;
                    var result = null; 
                    for(let i=0;i<res.data.data.data.sjlist.length;i++){
                            result = gcoord.transform(
                            [res.data.data.data.sjlist[i].lng,res.data.data.data.sjlist[i].lat],                            // 经纬度坐标
                            gcoord.WGS84,                   // 当前坐标系
                            gcoord.BD09                     // 目标坐标系
                        );
                        res.data.data.data.sjlist[i].lng = result[0];
                        res.data.data.data.sjlist[i].lat = result[1];
                        
                        this.$set(newlinePath,i,res.data.data.data.sjlist[i]);
                        this.dpstop = newlinePath[0];
                        this.$set(this.newlinePath,i,res.data.data.data.sjlist[i]);
                        // this.$set(this.path,i,res.data.data.data.sjlist[i]);
                    }
                    // console.log(newlinePath)
                    if(this.newlinePath.length > 0){
                        this.$set(this.centermap,'lng',this.newlinePath[0].lng);
                        this.$set(this.centermap,'lat',this.newlinePath[0].lat);
                    }
                }
            }
        })  
    },
    mounted(){

    },
    methods: {
        // 获取滑块进度
        lidformatip(val){
            if(val){
                console.log(val);
                console.log(newlinePath)
                // console.log(newlinePath[val])
                this.dpstop = newlinePath[val];
                console.log(this.dpstop)
                this.$forceUpdate();
            }
        },
        // 停止播放
        reset () {
            this.play = false;
            clearInterval(this.intartime);
            this.intartime = null;
        },
        // 开始播放
        starts() {
            this.play = true;
            this.intartime = setInterval(this.timego,1000);
        },
        timego(){
            this.vlidalue ++;
            this.dpstop = newlinePath[this.vlidalue];
            console.log(this.dpstop)
            this.$forceUpdate();
            if(this.vlidalue == this.slidermax){
                clearInterval(this.intartime);
                this.intartime = null;
                this.vlidalue = 1;
                this.dpstop = newlinePath[newlinePath.length];
                this.play = false;
            }
        },
        // handleSearchComplete (res) {
        //     this.AddData.path = this.path;
        // },

      // 返回
      Addopen() {
        sessionStorage.removeItem('queryAll');
        this.$router.push({name:"AlarmRecord"});
      },
    },
};
</script>
<style lang="less" scoped> 
.conenters{
    width: 100%;
    height: calc(100vh - 68px);
    overflow: hidden;
}
// 头部开始
.pur-top {
    width: 100%;
    height: 50px;
    padding: 9px 24px 9px 32px;
    background-color: #fff;
    box-sizing: border-box;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    border: 1px solid rgba(102, 102, 102, 0.15);
    .pur-size {
        font-size: 16px;
        line-height: 32px;
        color: #333;
        font-weight: 600;
    }
    .pur-right {
        .el-input {
        width: 200px;
        float: right;
        }
        .el-button {
        margin-left: 16px;
        float: right;
        }
    }
}
// 头部结束
// 内容开始
.pur-nav {
    width: calc(100% - 16px);
    height: calc(100% - 32px);
    background-color: rgb(247, 240, 240);
    margin: 8px;
    box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
    padding: 8px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    .bm-view{
        width: 100%;
        height:100%;
    }
    .mapBox{
        width: 242px;
        position: absolute;
        top: 20px;
        left:20px;
        z-index: 999; 
        list-style-type: none;
        background: oldlace;
        li{
            width: 60%;
            height: 38px;
            padding: 10px 0 10px 10px;
            box-sizing: border-box;
            float: left;
            span{
                font-size: 16px;
                line-height: 18px;
                color: #333;
            }
            p{
                display: inline-block;
                width: 90%;
                height: 18px;
                opacity: 0.6;
            }
        }
    }
    .speedBox{
        width: 80%;
        height: 50px;
        background: #333;
        // opacity: .6;
        position: absolute;
        bottom: 60px;
        left: 10%;
        padding: 5px 10px;
        box-sizing: border-box;
        border-radius: 8px;
        .ibuttsn{
            width: 40px;
            height: 40px;
            float: left;
            i{
                font-size: 40px;
                line-height: 40px;
                color: #fff;
            }
        }
        .sliderbox{
            width: calc(100% - 40px);
            height: 100%;
            float: left;
            padding: 0 20px;
            box-sizing: border-box;
        }
    }
}
// 内容结束
</style>